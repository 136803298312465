<template>
    <v-card class="pt-2" elevation="0" >
      <v-col>
        <v-row class="cardBgColor flex-nowrap" align="center">
          <v-col class=""> {{ display }} </v-col>
          <v-col v-if="supportDelete == true" class="flex-grow-0 pl-0">
            <v-btn icon small @click="deleteFilter">
              <Icon
                class="primaryColorOnHover"
                icon="akar-icons:circle-x-fill"
                style="cursor: pointer"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </template>
  <script>
  /*
    Name:LocationFilterTag
    Description:Card to show selected filters giving the delete action per filter on user
    API Property   
    display: String containing the description shown in the card
    attrHead: Array containing the name of the attribute and the action name, Examples:
      1.*** [{ name: 'available', action: "onSetAvailable" }]
      2.*** [{ name: 'priceMin', action: "onSetPriceMin" }, { name: 'priceMax', action: "onSetPriceMax" }],
    attrValue: [String, Number, Boolean, Array] containing the value of the attribute Examples: 
      1.*** category.val,
      2.*** [priceMin, priceMax]
    attrIndex: Index position of attribute in array
    attrType: String containing types For Example
      1.*** "string"
      2.*** "arrayByVal"
      3.*** "array"
      4.*** "commaSeparated"
    supportDelete: Boolean variable to show/hide the Delete button
    deleteHandler: Handler function from parent when delete is pressed  
  
    Components Used:
      Icon
  */
  import { Icon } from "@iconify/vue2";
  export default {
    name: "LocationFilterTag",
    components: {
      Icon,
    },
    props: {
      display: String,
      attrHead: Array,
      attrValue: [String, Number, Boolean, Array],
      attrIndex: Number,
      attrType: String,
      supportDelete: { type: Boolean, default: true },
      deleteHandler: Function,
    },
  
    methods: {
      deleteFilter() {
        try {
          this.deleteHandler(
            this.attrHead,
            this.attrType,
            this.attrValue,
            this.attrIndex
          );
        } catch (e) {
          console.error(e);
        }
      },
    },
  };
  </script>
  <style scoped>
  .cardBgColor {
    background-color: #deebe1;
    min-height: 32px;
    width: 100%;
  }
  </style>
