var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.cardClass,attrs:{"min-width":"280","max-width":"416","min-height":"435"}},[_c('v-row',{staticClass:"cardContent",staticStyle:{"cursor":"pointer"}},[_c('v-col',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-7"},[_c('v-img',{class:{ opacity: !_vm.isApproved, imgContent: true },attrs:{"height":"203","src":_vm.tempImage,"lazy-src":_vm.tempImage,"max-width":"379"},on:{"error":_vm.imageError}},[_c('v-row',{staticClass:"h50"},[_c('v-col',{staticClass:"d-flex align-end"},[_c('v-row',{staticClass:"pl-6 d-flex align-center"},[(_vm.booleanVip)?_c('span',{staticClass:"textDecorationBold vipClass mr-2"},[_vm._v(" VIP ")]):_vm._e(),(_vm.showDifPrice)?_c('span',{staticClass:"textDecorationBold discountClass pl-2 pr-2"},[_vm._v(" "+_vm._s(_vm.getPrice.type + " " + _vm.getPrice.percentage + "%")+" ")]):_vm._e()])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pa-0"},[_c('v-card-title',{staticClass:"textDecorationBold pb-2",staticStyle:{"color":"#263573"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.txtLength(
                  (_vm.title != undefined ? _vm.title : '') +
                    ' ' +
                    (_vm.unit != undefined ? '|' + _vm.unit + ' τ.μ.' : '')
                ) < (_vm.mobileBreakpoint == true ? 60 : 28)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"font16 pb-0 text-truncate",attrs:{"align":"left"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textOverFlow( (![undefined, "", null].includes(_vm.title) ? _vm.title : "--") + " " + (![undefined, "", null].includes(_vm.unit) ? "|" + _vm.unit + " τ.μ." : "--"), _vm.mobileBreakpoint == true ? 60 : 27 ))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(![undefined, "", null].includes(_vm.title) ? _vm.title : "--")+" "+_vm._s(![undefined, "", null].includes(_vm.unit) ? "|" + _vm.unit + " τ.μ." : "--"))])])],1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pa-0"},[_c('v-card-text',{staticClass:"textFontDecoration"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('h3',{staticClass:"font14 textContent textDecorationBold",attrs:{"align":"left"}},[_vm._v(" "+_vm._s(![undefined, "", null].includes(_vm.price) ? _vm.price : "--")+" ")])])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.txtLength(_vm.location) < 33},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"textContent font12 pb-0 text-truncate",attrs:{"align":"left"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(![undefined, "", null].includes(_vm.location) ? _vm.textOverFlow( _vm.location, _vm.mobileBreakpoint == true ? 60 : 32 ) : "--")+" ")])]}}])},[_c('span',[_vm._v(_vm._s(![undefined, "", null].includes(_vm.location) ? _vm.location : "--"))])])],1)],1),_c('v-row',{staticClass:"mt-0 mb-0"},[_c('v-col',{staticClass:"pt-2 pb-0"},[_c('div',{staticClass:"\n                    textContent\n                    textDecorationBold\n                    font14\n                    veryDarkGrayColor\n                  ",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.code)+" ")])])],1),_c('v-row',{staticClass:"mt-0 flex-nowrap",attrs:{"align":"center"}},[_c('v-col',{staticClass:"\n                  text-no-wrap\n                  textContent\n                  textDecorationBold\n                  ml-3\n                  flex-grow-0\n                "},[_c('span',[_vm._v(" "+_vm._s(![undefined, "", null].includes(_vm.typeName) ? _vm.typeName : "--")+" ")])]),(_vm.mobileBreakpoint != true)?_c('v-col',{staticClass:"mb-2 flex-grow-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"font16 pb-0",attrs:{"align":"left"}},'div',attrs,false),on),[_c('v-img',{attrs:{"height":"23px","width":"23px","src":require('@/assets/realEstateCard/global.svg')}})],1)]}}],null,false,2885221128)},[_c('span',[_vm._v(" "+_vm._s(_vm.timeAgo(_vm.RegisterationDay))+" ")])])],1):_vm._e(),_c('v-col',{staticClass:"pl-0"},[_c('div',{staticClass:"text-no-wrap textContent textFontDecoration font14",staticStyle:{"color":"#263573"},attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.typeExtraContent)+" ")])])],1),_c('div',[_c('v-col',{staticClass:"pt-0"},[_c('v-divider')],1)],1),_c('v-row',{staticClass:"pt-4 justify-start align-end pb-9"},[_c('AvatarField',{attrs:{"data":_vm.getPhoto(_vm.fromDetails),"isImg":_vm.fromDetails.AgentPhoto ? true : false,"showName":true,"nameContent":(_vm.fromDetails.AgentName ? _vm.fromDetails.AgentName + ' ' : '') +
                  (_vm.fromDetails.AgentSurName ? _vm.fromDetails.AgentSurName : ''),"fontSizeName":"14px","showSubContent":true,"subContent":_vm.fromDetails.LastCompany ? _vm.fromDetails.LastCompany : '',"backgroundColor":"#77BD86","size":32,"fontSizeImg":"14px","nameContentClass":"avatar-name-class","subNameContentClass":"avatar-subname-class"}})],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"forApproveClass justify-center ma-0 textDecorationBold"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }