import { render, staticRenderFns } from "./DemandEstateCard.vue?vue&type=template&id=000c8244&scoped=true&"
import script from "./DemandEstateCard.vue?vue&type=script&lang=js&"
export * from "./DemandEstateCard.vue?vue&type=script&lang=js&"
import style0 from "./DemandEstateCard.vue?vue&type=style&index=0&id=000c8244&scoped=true&lang=css&"
import style1 from "./DemandEstateCard.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "000c8244",
  null
  
)

export default component.exports