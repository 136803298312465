<template>
   
      <v-col class="px-0 card">
 
        <v-row class="ml-7 py-5 mt-n5">
          <v-col 
            cols="12"
            xs="12"
            sm="7"
            md="6"
            lg="6"
            xl="6"
            :align="mobileBreakpoint ? 'left' : ''"
            :class="mobileBreakpoint ? 'pl-0' : ''"
          >
            <HeaderTitle
              :title="$t('callType')
"
            />
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="5"
            md="6"
            lg="6"
            xl="6"
            :align="mobileBreakpoint ? 'right' : ''"
            :class="mobileBreakpoint ? 'pr-8' : ''"
          >
            <a
              v-if="type !== 'edit'"
              class="textFontDecoration"
              :style="'color: #E52628; font-size: 14px;  font-weight: bold; margin-right: 30px'"
              @click="onClearHandler"
            >
              {{ $t("clear") }}
            </a>
            <v-btn
              v-if="type == 'create'"
              class="btn-custom mb-1 mr-1"
              @click="continueHandlerInit('create')"
              color="error"
              max-width="129"
              max-height="38"
              min-width="129"
              min-height="38"
              :loading="loading"
            >
              {{ $t("continue") }}
            </v-btn>
            <v-btn
              v-else
              class="btn-custom mb-1 mr-1"
              @click="continueHandlerInit('edit')"
              color="error"
              max-width="129"
              max-height="38"
              min-width="129"
              min-height="38"
              :loading="loading"
            >
              {{ $t("save") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="pb-5"></v-divider>
        <v-row class="ml-9 pt-4 call-registration-body overflow-auto">
          <v-col class="pr-7" cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
            <v-row v-if="type !== 'edit'">
              <v-col
                align="left"
                class="pt-0 pb-2"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("recommendedEstate") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="type != 'edit'">
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-combobox
                  dense
                  v-model="realEstateCodeRequested"
                  :label="$t('enterRealEstateCode')"
                  :search-input.sync="propertySearch"
                  outlined
                  small-chips
                  :items="estateCodesArray"
                  item-text="EstateCode"
                  :loading="propertyLoader"
                  :error-messages="estateCodeRequestErr"
                  return-object
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      label
                      small
                      class="estate-chip"
                    >
                      <span class="pr-2"> {{ item.EstateCode }} </span>
                      <v-icon small @click="parent.deleteCurrentItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row v-if="![null, undefined, ''].includes(cardData)">
              <v-col class="pb-11 pt-0">
                <DemandEstateCard
                  :fromDetails="avatarFieldData ? avatarFieldData : ''"
                  :id="cardData._id"
                  :key="cardData.EstateCode ? cardData.EstateCode : ''"
                  :image="cardData.MainPhoto ? cardData.MainPhoto : ''"
                  :title="cardData.Type"
                  :unit="cardData.Unit"
                  :price="cardData.Price"
                  :location="cardData.Location"
                  :code="cardData.EstateCode ? cardData.EstateCode : ''"
                  :comments="cardData.comments ? cardData.comments : 0"
                  :rating="cardData.reviews ? cardData.reviews : []"
                  :status="cardData.Status"
                  :typeExtraContent="cardData.Assignation"
                  :typeName="cardData.Task"
                  :isApproved="cardData.IsApproved"
                  :prices="cardData.prices"
                  :booleanVip="
                    cardData.characteristics &&
                    parseBoolean(cardData.characteristics.vip)
                  "
                  :RegisterationDay="
                    cardData.RegisterationDay ? cardData.RegisterationDay : ''
                  "
                  :authObj="{
                    UserID: cardData.UserID,
                    CustomerID: cardData.CustomerID,
                    Company: cardData.Company,
                  }"
                />
              </v-col>
            </v-row>
            <v-row class="mt-n6" v-if="!cardData">
              <v-col class="textDecorationBold font14" align="left">
                <span> {{ $t("propertyCategories") + "*" }} </span>
                <span class="errorColor font12"> {{ categoryErrors[0] }} </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col class="pr-0" align="left">
                <v-btn-toggle v-model="Category" mandatory>
                  <v-btn
                    v-for="button in getCategoryList"
                    :key="button.id"
                    class="mr-3 my-1 btn recBtn"
                    outlined
                    :value="button.val.toString()"
                    :max-width="126"
                    :min-width="126"
                    :max-height="108"
                    :min-height="108"
                  >
                    <v-col>
                      <v-row>
                        <v-col align="center" class="pb-0">
                          <v-img
                            :src="
                              require('@/assets/CreateEditRealEstate/' +
                                button.id +
                                '.svg')
                            "
                            height="48"
                            width="48"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span> {{ $t(button.text) }} </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col class="mt-n2" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-select
                  v-model="subCategory"
                  :items="getSubCategoryList"
                  :height="30"
                  :label="$t('selectPropSubcat') + '...'"
                  item-text="text"
                  item-value="val"
                >
                  <template v-slot:selection="{ item }">
                    <v-row class="flex-grow-0">
                      <v-col style="display: flex; align-items: center">
                        {{ $t(item.text) }}
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list>
                      <v-list-item-title>
                        <v-row>
                          <v-col style="display: flex; align-items: center">
                            {{ $t(item.text) }}
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-n3 mb-n5" v-if="!cardData">
              <v-col align="left">
                <span class="textDecorationBold font14">
                  {{ $t("coopTo") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col class="pb-5" align="left">
                <v-btn-toggle v-model="Task" flex>
                  <v-btn
                    v-for="button in availableForList"
                    :key="button.id"
                    :min-width="126"
                    :max-width="126"
                    :min-height="40"
                    :max-height="40"
                    :value="button.val.toString()"
                    class="btn recBtnWidth"
                    :class="mobileBreakpoint ? 'mb-2 mr-2' : 'mr-3'"
                    outlined
                  >
                    {{ $t(button.text) }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row class="mt-1 mb-n5" v-if="!cardData">
              <v-col align="left">
                <span class="textDecorationBold font14">
                  {{ $t("budget") + "*" }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col class="" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  outlined
                  dense
                  v-model="budgetFrom"
                  type="number"
                  :error-messages="budgetFromErrors"
                  :label="$t('from')"
                  :class="mobileBreakpoint ? 'mb-n5' : ''"
                >
                  <template slot="append">
                    <span
                      class="textDecorationBold primaryColor font14"
                      style="margin-top: 4px"
                    >
                      {{ currency }}
                    </span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  outlined
                  dense
                  v-model="budgetTo"
                  :error-messages="budgetToErrors"
                  type="number"
                  :label="$t('toDt2') + '...'"
                >
                  <template slot="append">
                    <span
                      class="textDecorationBold primaryColor font14"
                      style="margin-top: 4px"
                    >
                      {{ currency }}
                    </span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="pb-2 pt-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold goldText">
                  {{ $t("originData") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-n3" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-select
                  :max-width="272"
                  :min-width="272"
                  v-model="sourceType"
                  :items="originDataArray"
                  item-value="val"
                  :label="$t('requestCameFrom')"
                />
              </v-col>
              <v-col
                v-if="sourceType == 'Direct'"
                class="mt-n3"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <v-select
                  :max-width="272"
                  :min-width="272"
                  height="29"
                  :items="sourceDataArray"
                  :max-height="29"
                  :min-height="29"
                  item-value="text"
                  v-model="source"
                  :label="$t('requestCameFrom')"
                />
              </v-col>
              <v-col
                v-if="sourceType == 'Agent'"
                class="mt-n3"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <v-autocomplete
                  :max-width="272"
                  :min-width="272"
                  height="29"
                  :max-height="29"
                  :min-height="29"
                  :items="agentsSourceList"
                  :filter="filterAgents"
                  v-model="source"
                  return-object
                  :search-input.sync="searchSource"
                  :loading="autocompleteLoaderAgent"
                  item-text="fullname"
                  item-value="_id"
                  :label="$t('patnerSearch')"
                  attach
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="py-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("comments") }}
                </span>
              </v-col>
            </v-row>
  
            <v-row>
              <v-col cols="12">
                <v-textarea
                  class="borderOutlined"
                  v-model="Comments"
                  no-resize
                  height="79"
                  outlined
                  :label="$t('optionalComments') + '...'"
                />
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col
                align="left"
                class="pt-0"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold goldText">
                  {{ $t("realEstateFeatures") + "*" }}
                </span>
                <span class="errorColor font12">
                  {{ locationErrors[0] }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData" style="margin-right: -120px">
              <v-col
                class="pr-0 mr-n15"
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <Location
                  :placeholder="$t('searchLoc') + ' Google...'"
                  :showNewDealList="true"
                  iconColor="primary"
                  :setLocation="setACLocation"
                  :vuexProps="false"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="
                !cardData && (locationGoogle.length > 0 || location.length > 0)
              "
              class="pb-6"
              style="margin-right: -80px"
            >
              <v-col align="left" class="filter-tag pr-0" cols="6">
                <LocationFilterTag
                  v-for="(tag, index) in locationGoogle"
                  :key="index"
                  :display="$t(tag.description)"
                  :attrType="String(index)"
                  :deleteHandler="deleteLocationGoogleFilter"
                />
              </v-col>
              <v-col align="left" class="filter-tag pr-0" cols="6">
                <LocationFilterTag
                  v-for="(tag, index) in location"
                  :key="index"
                  :display="$t(tag.description)"
                  :attrType="String(index)"
                  :deleteHandler="deleteLocationFilter"
                />
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col
                class="py-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("areasqm") + "*" }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData && categoryValue == 2">
              <v-col class="pb-0" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <RadioField
                  :val="selectedMu"
                  fieldName="selectedMu"
                  :isRow="true"
                  :options="MuRadioFields"
                  radioValues="MuRadioFields"
                  :radioChangeHandler="updateRadioField"
                />
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="areasqmFrom"
                  :label="$t('from') + '.'"
                  type="number"
                  outlined
                  dense
                  :error-messages="areasqmFromErrors"
                  :suffix="$t(measurementUnit)"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="areasqmTo"
                  :label="$t('toDt2') + '...'"
                  outlined
                  dense
                  type="number"
                  :error-messages="areasqmToErrors"
                  :suffix="$t(measurementUnit)"
                />
              </v-col>
            </v-row>
            <v-row v-if="!cardData && ![1, 2, 3].includes(categoryValue)">
              <v-col
                class="py-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("plotAreasqm") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData && ![1, 2, 3].includes(categoryValue)">
              <v-col class="" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="plotAreasqmFrom"
                  :label="$t('from') + '.'"
                  type="number"
                  outlined
                  dense
                >
                  <template slot="append">
                    <span
                      class="textDecorationBold primaryColor font14"
                      style="margin-top: 4px"
                    >
                      {{ $t("sqm") }}
                    </span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="plotAreasqmTo"
                  :label="$t('toDt2') + '...'"
                  type="number"
                  outlined
                  dense
                >
                  <template slot="append">
                    <span
                      class="textDecorationBold primaryColor font14"
                      style="margin-top: 4px"
                    >
                      {{ $t("sqm") }}
                    </span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="!cardData && categoryValue == 2">
              <v-col
                class="py-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("facade2") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData && categoryValue == 2">
              <v-col class="" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="facadeFrom"
                  :label="$t('from') + '.'"
                  type="number"
                  outlined
                  dense
                  :suffix="$t('meters')"
                />
              </v-col>
              <v-col class="" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="facadeTo"
                  :label="$t('toDt2') + '...'"
                  type="number"
                  outlined
                  dense
                  :suffix="$t('meters')"
                />
              </v-col>
            </v-row>
            <v-row v-if="!cardData && categoryValue == 2">
              <v-col
                class="py-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("build") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData && categoryValue == 2">
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="buildFrom"
                  :label="$t('from') + '.'"
                  type="number"
                  outlined
                  dense
                  :suffix="$t('sqft3')"
                />
              </v-col>
              <v-col class="" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="buildTo"
                  :label="$t('toDt2') + '...'"
                  type="number"
                  outlined
                  dense
                  :suffix="$t('sqft3')"
                />
              </v-col>
            </v-row>
            <v-row v-if="!cardData && ![1, 2, 3].includes(categoryValue)">
              <v-col
                class="py-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("bedrooms") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData && ![1, 2, 3].includes(categoryValue)">
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="bedroomsFrom"
                  :label="$t('from') + '.'"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="bedroomsTo"
                  :label="$t('toDt2') + '...'"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row v-if="!cardData && ![1, 2, 3].includes(categoryValue)">
              <v-col
                class="py-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("bathrooms") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData && ![1, 2, 3].includes(categoryValue)">
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="bathroomsFrom"
                  :label="$t('from') + '.'"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col class="" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field
                  v-model="bathroomsTo"
                  :label="$t('toDt2') + '...'"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row v-if="!cardData && categoryValue != 2">
              <v-col
                class="py-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("floors") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData && categoryValue != 2">
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <v-select
                                v-model="floorsFrom"
                                :items="floorsList"
                                :label="$t('from') + '.'"
                                item-value="val"
                                return-object
                                outlined
                                height="30"
                                dense
                                clearable
                              >
                                <template v-slot:selection="{ item, index }">
                                  <v-row>
                                    <v-col>
                                      <span v-if="index == 0">
                                        {{ $t(item.text) }}
                                      </span>
                                      <span
                                        v-if="floorsFrom.length > 1 && index == 0"
                                        class="grey--text text-caption"
                                      >
                                        + {{ floorsFrom.length - 1 }}
                                        {{ $t("moreSelected") }}
                                      </span>
                                    </v-col>
                                  </v-row>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list>
                                    <v-list-item-title>
                                      <v-row>
                                        <v-col
                                          style="display: flex; align-items: center"
                                        >
                                          {{ $t(item.text) }}
                                        </v-col>
                                      </v-row>
                                    </v-list-item-title>
                                  </v-list>
                                </template>
                              </v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-select
                  v-model="floorsTo"
                  :items="floorsList"
                  :label="$t('toDt2') + '...'"
                  item-value="val"
                  return-object
                  outlined
                  height="30"
                  dense
                  clearable
                >
                  <template v-slot:selection="{ item, index }">
                    <v-row>
                      <v-col>
                        <span v-if="index == 0">
                          {{ $t(item.text) }}
                        </span>
                        <span
                          v-if="floorsTo.length > 1 && index == 0"
                          class="grey--text text-caption"
                        >
                          + {{ floorsTo.length - 1 }}
                          {{ $t("moreSelected") }}
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list>
                      <v-list-item-title>
                        <v-row>
                          <v-col
                            style="display: flex; align-items: center"
                          >
                            {{ $t(item.text) }}
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="!cardData && categoryValue != 2">
              <v-col
                class="py-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("yearOfConstruction") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData && categoryValue != 2">
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <v-text-field
                      clearable
                      dense
                      outlined
                      type="number"
                      :class="mobileBreakpoint == true ? 'mb-n5' : ''"
                      :label="$t('from')"
                      v-model="constructionStart" 
                    />
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <v-text-field
                      clearable
                      dense
                      outlined
                      type="number"
                      :label="$t('toDt2') + '...'"
                      v-model="constructionEnd"         
                    />
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col
                class="py-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("otherFeatures2") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col class="py-0" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-select
                  :items="buildingStatusList"
                  v-model="status"
                  multiple
                  :label="$t('status')"
                  item-text="text"
                  item-value="text"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-row>
                      <v-col>
                        <span v-if="index == 0">
                          {{ $t(item.text) }}
                        </span>
                        <span
                          v-if="status.length > 1 && index == 0"
                          class="grey--text text-caption"
                        >
                          + {{ status.length - 1 }} {{ $t("moreSelected") }}
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list>
                      <v-list-item-title>
                        <v-row>
                          <v-col style="display: flex; align-items: center">
                            {{ $t(item.text) }}
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="status.length && !cardData" class="pb-6">
              <v-col align="left" class="d-flex flex-nowrap overflow-auto pt-0">
                <FilterTag
                  v-for="(tag, index) in status"
                  :key="index"
                  :display="$t(tag)"
                  :attrType="String(index)"
                  :deleteHandler="deleteFilter"
                />
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col
                class="pt-5 pb-3"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("more") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col
                v-for="(index, i) in featureListFilter"
                :key="i"
                class="mt-n7 mb-n6"
                cols="6"
                xs="6"
                sm="4"
                md="4"
                lg="4"
                xl="4"
              >
                <v-checkbox
                  class="primaryColor my-checkbox"
                  v-model="propertyCharacteristics"
                  :label="$t(index.text)"
                  :value="index.text"
                  :size="22"
                />
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col
                class="pb-0 pt-9"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold font14">
                  {{ $t("description") + "*" }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="!cardData">
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-textarea
                  class="borderOutlined"
                  v-model="description"
                  :error-messages="descriptionErrors"
                  no-resize
                  height="124"
                  outlined
                  :label="$t('demandDescription') + '...'"
                />
              </v-col>
            </v-row>
            <v-row v-if="cardData">
              <v-col
                class="pb-0"
                align="left"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <span class="textDecorationBold goldText font14">
                  {{ $t("searchComment") }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="cardData">
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-textarea
                  class="borderOutlined"
                  v-model="description"
                  :error-messages="descriptionErrors"
                  no-resize
                  height="124"
                  outlined
                  :label="$t('demandDescription') + '...'"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-dialog
        v-if="dialog.type == 'createContact'"
        v-model="dialog.open"
        max-width="690px"
        scrollable
      >
        <Contact
          parent="demands"
          :subTitle="$t('interested2')"
          :searchOwner="initCustomer"
          :closeHandler="closeDialog"
        />
      </v-dialog>
      <v-dialog
        v-if="dialog.type == 'createEvaluation'"
        v-model="dialog.open"
        max-width="820px"
        scrollable
      >
        <ContactEvaluation
          :customer="customer"
          :closeDialog="closeDialog"
          :onSubmit="onSubmitEval"
          :title="type == 'edit' ? $t('entryDemand') :  $t('demandRegistration')"
        />
      </v-dialog>
      <v-dialog
        v-if="dialog.type == 'congratsPopUp'"
        v-model="dialog.open"
        :max-width="625"
        :max-height="213"
      >
        <CongratsPopup
          :title="$t('successfullyRequest')"
          :onCloseHandler="onCloseAfterCreateHandler"
        />
      </v-dialog>
      </v-col>
     

  </template>
  <script>
  /*
   Name:CreateEditDemand
   Description: Component for demand creation
  
   API Property: 
      content: Object for data
      type: String for create or edit
      onCloseHandler: Function for popup close
      onCloseAfterCreate: Function for close after create
      onCloseEdit: Function for close after edit
      
      Components Used:
        HeaderTitle
        Location
        FilterTag
        LocationFilterTag
        Contact
        RadioField
        DemandEstateCard
        DemandsStaticLists
        ContactEvaluation
        CongratsPopup
        Repository
        
  
      Endpoints Functions Called:
        -RealEstateRepo
          get
  
        -RequestDemandRepo
          create
          update
  
        -ContactRepo
          get
          getAgents
          getOwners
          getCustomers
          getId  
  */
  
  import RadioField from "@/components/Fields/RadioField/RadioField.vue";
  import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
  import CoreHelper from "@/helpers/Core/core";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import { mapGetters, mapState } from "vuex";
  import Location from "@/components/Maps/Location.vue";
  import LocationFilterTag from "@/components/Filters/LocationFilterTag.vue";
  import DemandsStaticLists from "@/static/demands/demands.js";
  import DemandEstateCard from "@/components/Demands/DemandEstateCard";
  import FilterTag from "@/components/Filters/FilterTag.vue";
  import Contact from "@/components/Contacts/Contact.vue";
  import ContactEvaluation from "@/components/Demands/ContactEvaluation";
  import CongratsPopup from "@/components/Popups/CongratsPopup";
  import i18n from "@/i18n/i18n";
  import Repository from "@/services/Repository";
  
  const RealEstateRepo = Repository.get("realEstate");
  const RequestDemandRepo = Repository.get("reqDemand");
  const ContactRepo = Repository.get("contacts");
  
  export default {
    name: "CreateEditDemand",
    components: {
      HeaderTitle,
      Location,
      FilterTag,
      LocationFilterTag,
      Contact,
      RadioField,
      DemandEstateCard,
      ContactEvaluation,
      CongratsPopup,
    },
    props: {
      content: Object,
      type: String,
      onCloseHandler: Function,
      onCloseAfterCreate: Function,
      onCloseEdit: Function,
    },
    data() {
      return {
        loading: false,
        id: null,
        dataCreated: null,
        cardData: null,
        avatarFieldData: {},
        assigned: null,
        requestStatus: null,
        requestDate: null,
        searchAssigned: null,
        menu: false,
        menu1: false,
        menu2: false,
        menu3: false,
        constructionStart: null,
        constructionEnd: null,
        nextFollowUpFormatted: null,
        nextFollowUp: null,
        Category: "",
        subCategory: null,
        Task: null,
        budgetFrom: null,
        budgetTo: null,
        realEstateCodeRequested: null,
        sourceType: null,
        Comments: null,
        areasqmFrom: null,
        areasqmTo: null,
        plotAreasqmFrom: null,
        plotAreasqmTo: null,
        bedroomsFrom: null,
        bedroomsTo: null,
        facadeTo: null,
        facadeFrom: null,
        buildFrom: null,
        buildTo: null,
        bathroomsFrom: null,
        bathroomsTo: null,
        floorsFrom: null,
        floorsTo: null,
        noPlacesFrom: null,
        noPlacesTo: null,
        constructionFrom: null,
        constructionTo: null,
        sLocationDetails: null,
        locationsTagsList: [],
        status: [],
        propertyCharacteristics: [],
        description: null,
        show: true,
        featuresList: DemandsStaticLists.featuresList,
        conditionList: DemandsStaticLists.conditionList,
        availableForList: DemandsStaticLists.availableForList,
        dialog: {
          open: false,
          type: null,
          data: null,
        },
        customerCreated: false,
        search: null,
        MuRadioFields: [
          { label: i18n.t("meters2"), value: 0 },
          { label: i18n.t("acres"), value: 1 },
        ],
        selectedMu: 0,
        estateCodesArray: [],
        propertyLoader: false,
        propertySearch: null,
        agentAssignList: [],
        originDataArray: DemandsStaticLists.originData,
        sourceDataArray: DemandsStaticLists.sourceData,
        source: null,
        agentsSourceList: [],
        searchSource: null,
        customerList: [],
        searchCustomer: null,
        customerAutocompleteLoader: false,
        customer: null,
        location: [],
        locationGoogle: [],
        autocompleteLoaderAgent: false,
        autocompleteLoader: false,
        inCreated: true,
      };
    },
    watch: {
      show() {
        if (!this.show) this.onCloseHandler();
      },
      async propertySearch(code) {
        if (code != null) this.propertySearchArray(code);
        if (typeof this.cardData == "string") this.realEstateCodeRequested = null;
      },
      Category() {
        if(!this.inCreated){
          this.propertyCharacteristics = [];
          this.subCategory = null;
        }
      },
      searchCustomer(val) {
        val && this.fetchCustomerList(val, "splitName");
      },
      searchAssigned(val) {
        val && this.fetchAssignAgentsList(val);
      },
      searchSource(val) {
        val && this.fetchSourceAgentList(val);
      },
      realEstateCodeRequested(data) {
        if (typeof data != "string") {
          this.cardData = data;
        }
        if (data) {
          this.avatarFieldData = {
            AgentName: data.AgentName ? data.AgentName : null,
            AgentPhoto: data.AgentPhoto ? data.AgentPhoto : "",
            AgentSurName: data.AgentSurName ? data.AgentSurName : "",
            LastCompany: data.LastCompany ? data.LastCompany : "",
          };
        }
        this.propertySearch = null;
      },
      nextFollowUp(val) {
        this.nextFollowUpFormatted = CoreHelper.formatDate(val);
      },
      sLocationDetails() {
        if (this.sLocationDetails.length != 0) {
          this.state = this.sLocationDetails.vicinity
            ? this.sLocationDetails.vicinity
            : "";
          if (this.sLocationDetails.addressExtracted) {
            this.region = this.sLocationDetails.addressExtracted
              .administrative_area_level_1
              ? this.sLocationDetails.addressExtracted.administrative_area_level_1
              : this.sLocationDetails.addressExtracted.administrative_area_level_2
              ? this.sLocationDetails.addressExtracted.administrative_area_level_2
              : this.sLocationDetails.addressExtracted.administrative_area_level_3
              ? this.sLocationDetails.addressExtracted.administrative_area_level_3
              : "";
            this.district = this.sLocationDetails.addressExtracted.locality
              ? this.sLocationDetails.addressExtracted.locality
              : "";
            this.borough = this.sLocationDetails.addressExtracted.neighborhood
              ? this.sLocationDetails.addressExtracted.neighborhood
              : this.sLocationDetails.addressExtracted.route
              ? this.sLocationDetails.addressExtracted.route
              : "";
          }
          this.address = this.sLocationDetails.formatted_address
            ? this.sLocationDetails.formatted_address
            : "";
          let lat =
            this.sLocationDetails?.geometry &&
            this.sLocationDetails?.geometry?.location &&
            this.sLocationDetails?.geometry?.location?.lat
              ? this.sLocationDetails?.geometry?.location?.lat()
              : null;
          let lng =
            this.sLocationDetails?.geometry &&
            this.sLocationDetails?.geometry?.location &&
            this.sLocationDetails?.geometry?.location?.lng
              ? this.sLocationDetails?.geometry?.location?.lng()
              : null;
          this.gps = lat != null && lng != null ? lat + "," + lng : ",";
        }
      },
    },
    validations() {
      return {
        description: { required },
        assigned: {
          required: requiredIf(function () {
            return !this.cardData;
          }),
        },
        requestStatus: { required },
        Category: {
          required: requiredIf(function () {
            return !this.cardData;
          }),
        },
        realEstateCodeRequested: {
          required: requiredIf(function () {
            return this.propertySearch;
          }),
        },
        budgetFrom: {
          required: requiredIf(function () {
            return !this.budgetTo && !this.cardData;
          }),
        },
        budgetTo: {
          required: requiredIf(function () {
            return !this.budgetFrom && !this.cardData;
          }),
        },
        areasqmTo: {
          required: requiredIf(function () {
            return !this.areasqmFrom && !this.cardData;
          }),
        },
        areasqmFrom: {
          required: requiredIf(function () {
            return !this.areasqmTo && !this.cardData;
          }),
        },
        location: {
          required: requiredIf(function () {
            return !this.cardData && !this.locationGoogle.length > 0;
          }),
        },
        locationGoogle: {
          required: requiredIf(function () {
            return !this.cardData && !this.location.length > 0;
          }),
        },
      };
    },
    computed: {
      ...mapGetters(["realEstateCategoriesList", "buildingStatusList", "floorsList"]),
      ...mapState({
        userDetails: (state) => state.auth.userDetails,
        settings: (state) => state.settings,
      }),
      mobileBreakpoint() {
        return this.$vuetify.breakpoint.xsOnly;
      },
      dateLang() {
        return this.settings.language == "gr" ? "el" : this.settings.language;
      },
      currency() {
        return CoreHelper.getCurrency(false);
      },
      featureListFilter() {
        var count = this.featuresList.filter((m) => {
          return m.category.includes(this.categoryValue);
        });
        return count;
      },
      conditionListFilter() {
        let data = this.conditionList.filter((a) => {
          if (a.text !== "new") return a;
        });
        return data;
      },
      hasClient() {
        return (
          this.type === "edit" &&
          ![undefined, "", null].includes(this.content?.Client)
        );
      },
      RegistrationDate() {
        return this.content?.RegistrationDate ?? false
          ? CoreHelper.EpochToDate(this.content.RegistrationDate)
          : "";
      },
      measurementUnit() {
        return this.selectedMu === 0 ? "meters2" : "acres2";
      },
      assignedErrors() {
        const errors = [];
        if (!this.$v.assigned.$dirty) return errors;
        if (!this.$v.assigned.required) errors.push(i18n.t("requiredField"));
        return errors;
      },
      budgetFromErrors() {
        const errors = [];
        if (!this.$v.budgetFrom.$dirty) return errors;
        if (!this.$v.budgetFrom.required) errors.push(i18n.t("requiredField"));
        return errors;
      },
      budgetToErrors() {
        const errors = [];
        if (!this.$v.budgetTo.$dirty) return errors;
        if (!this.$v.budgetTo.required) errors.push(i18n.t("requiredField"));
        return errors;
      },
      areasqmFromErrors() {
        const errors = [];
        if (!this.$v.areasqmFrom.$dirty) return errors;
        if (!this.$v.areasqmFrom.required) errors.push(i18n.t("requiredField"));
        return errors;
      },
      areasqmToErrors() {
        const errors = [];
        if (!this.$v.areasqmTo.$dirty) return errors;
        if (!this.$v.areasqmTo.required) errors.push(i18n.t("requiredField"));
        return errors;
      },
      locationErrors() {
        const errors = [];
        if (!this.$v.location.$dirty) return errors;
        if (!this.$v.location.required) errors.push(i18n.t("requiredField"));
        return errors;
      },
      locationGoogleErrors() {
        const errors = [];
        if (!this.$v.locationGoogle.$dirty) return errors;
        if (!this.$v.locationGoogle.required)
          errors.push(i18n.t("requiredField"));
        return errors;
      },
      descriptionErrors() {
        const errors = [];
        if (!this.$v.description.$dirty) return errors;
        if (!this.$v.description.required) errors.push(i18n.t("requiredField"));
        return errors;
      },
      requestStatusErrors() {
        const errors = [];
        if (!this.$v.requestStatus.$dirty) return errors;
        if (!this.$v.requestStatus.required) errors.push(i18n.t("requiredField"));
        return errors;
      },
      getCategoryList() {
        return this.realEstateCategoriesList.filter(function (el, i) {
          return 0 !== i;
        });
      },
      categoryErrors() {
        const errors = [];
        if (!this.$v.Category.$dirty) return errors;
        if (!this.$v.Category.required) errors.push(i18n.t("requiredField"));
        return errors;
      },
      estateCodeRequestErr() {
        const errors = [];
        if (!this.$v.realEstateCodeRequested.$dirty) return errors;
        if (!this.$v.realEstateCodeRequested.required)
          errors.push(i18n.t("requiredField"));
        return errors;
      },
      categoryValue() {
        return this.Category == "Επαγγελματικά"
          ? 1
          : this.Category == "Αξιοποιήσιμη Γη"
          ? 2
          : this.Category == "Επιχειρήσεις"
          ? 3
          : 0;
      },
      getSubCategoryList() {
        let subCatList = [];
        this.realEstateCategoriesList.forEach((element) => {
          if (this.categoryValue + 1 == element.id)
            subCatList.push.apply(subCatList, element.subCategoriesList);
        });
        return subCatList;
      },
    },
    async created() {
      this.inCreated = true;
      this.Category = "Κατοικία";
      this.requestStatus = "assigned";
      if (this.type === "edit") {
        this.Category = this.content.Category;
        this.Task = this.content?.Task;
        if (this.content?.Estate ?? false) {
          this.cardData = this.content.Estate;
          this.avatarFieldData = {
            AgentName: this.content?.Agent?.FirstName ?? "--",
            AgentPhoto: this.content?.Agent?.AgentPhoto ?? "",
            AgentSurName: this.content?.Agent?.LastName ?? "--",
            LastCompany: this.content?.Agent?.LastCompany ?? "--",
          };
        }
        this.Comments = this.content?.Comments ?? "";
        this.source = this.content?.source ?? null;
        this.agentsSourceList = [...this.agentsSourceList, this.source];
        this.sourceType = this.content?.sourceType ?? null;
        this.requestStatus = this.content?.RequestStatus;
        this.description = this.content?.description;
        if (this.content?.Client ?? false) {
          this.customer = {
            ...this.content.Client,
            name:
              this.content.Client?.FirstName +
              " " +
              (this.content.Client?.LastName ?? ""),
          };
          this.customerList = [...this.customerList, this.customer];
        }
        if (this.content?.follow_up ?? false){
          this.nextFollowUp = CoreHelper.EpochToDateFormatted(this.content.follow_up);
        }
        this.assigned = { ...this.content.Agent, name:(this.content.Agent?.FirstName ?? "") + " " + (this.content.Agent?.LastName ?? ""),
        };
        this.agentAssignList = [...this.agentAssignList, this.assigned];
        this.subCategory = this.content?.Type ?? null;
        if (this.content.Price) {
          this.budgetFrom =
            this.content.Price[0] != -999999999 ? this.content.Price[0] : null;
          this.budgetTo =
            this.content.Price[1] != 999999999 ? this.content.Price[1] : null;
        }
        if (this.content.Build) {
          this.buildFrom =
            this.content.Build[0] != -999999999 ? this.content.Build[0] : null;
          this.buildTo =
            this.content.Build[1] != 999999999 ? this.content.Build[1] : null;
        }
        if (this.content.Facade) {
          this.facadeFrom =
            this.content.Facade[0] != -999999999 ? this.content.Facade[0] : null;
          this.facadeTo =
            this.content.Facade[1] != 999999999 ? this.content.Facade[1] : null;
        }
        if (this.content.Floors) {
          this.floorsFrom =
            this.content.Floors[0] != -999999999 ? this.content.Floors[0] : null;
          this.floorsTo =
            this.content.Floors[1] != 999999999 ? this.content.Floors[1] : null;
        }
        if (this.content.Bedrooms) {
          this.bedroomsFrom =
            this.content.Bedrooms[0] != -999999999
              ? this.content.Bedrooms[0]
              : null;
          this.bedroomsTo =
            this.content.Bedrooms[1] != 999999999
              ? this.content.Bedrooms[1]
              : null;
        }
        if (this.content.Bathrooms) {
          this.bathroomsFrom =
            this.content.Bathrooms[0] != -999999999
              ? this.content.Bathrooms[0]
              : null;
          this.bathroomsTo =
            this.content.Bathrooms[1] != 999999999
              ? this.content.Bathrooms[1]
              : null;
        }
        if (this.content.Unit) {
          this.areasqmFrom =
            this.content.Unit[0] != -999999999 ? this.content.Unit[0] : null;
          this.areasqmTo =
            this.content.Unit[1] != 999999999 ? this.content.Unit[1] : null;
        }
        if (this.content.Land) {
          this.plotAreasqmFrom =
            this.content.Land[0] != -999999999 ? this.content.Land[0] : null;
          this.plotAreasqmTo =
            this.content.Land[1] != 999999999 ? this.content.Land[1] : null;
        }
        if (this.content.BuildYear) {
          this.constructionStart =this.content.BuildYear[0]
          this.constructionEnd = this.content.BuildYear[1]
        }
        if (this.content.property_characteristics) {
          this.propertyCharacteristics = this.content.property_characteristics;
        }
        if (
          this.content?.characteristics &&
          this.content.characteristics.length > 0
        ) {
          this.status = this.content.characteristics;
        }
        if (this.content.location) {
          this.location = this.content.location;
        }
        if (this.content.locationGoogle) {
          this.locationGoogle = this.content.locationGoogle;
        }
      } else {
        if (
          !["OfficeAdmin", "Secretary", "Admin", "Leader", "Attorney"].includes(
            this.userDetails.Role
          )
        ) {
          this.assigned = this.userDetails._id;
        }
        this.fetchAssignAgentsList(null);
        this.fetchSourceAgentList(null);
        this.inCreated = false;
      }
    },
    methods: {
      updateRadioField(data) {
        this[data.fieldName] = this[data.radioValues][data.selectedRadio].value;
      },
      parseBoolean(val) {
        return CoreHelper.parseBoolean(val);
      },
      setACLocation(loc) {
        if (loc.formatted_address) {
          let GPS = [];
          GPS[0] = loc.geometry.location.lat();
          GPS[1] = loc.geometry.location.lng();
          let object = { description: loc.formatted_address, GPS: GPS };
          this.locationGoogle.push(object);
        } else {
          for (let i = 0; i < loc.length; i++) {
            let location = {
              description: loc[i],
            };
            this.location.push(location);
          }
        }
        this.sLocationDetails = loc;
      },
      initCustomer(customer) {
        this.customer = {
          _id: customer.id,
          name: customer.fullname,
        };
        this.customerCreated = true;
      },
      async propertySearchArray(code) {
        try {
          let params;
          params = {
            page: 0,
            items: 10,
          };
          this.propertyLoader = true;
          let object = { $and: { EstateCode: [`${code}`] }, $or: {} };
          var resp = await RealEstateRepo.get(object, params);
  
          if (resp && resp.data && resp.data.estates)
            this.estateCodesArray = resp.data.estates;
  
          this.propertyLoader = false;
        } catch (err) {
          this.propertyLoader = false;
          console.error(err, "Error");
        }
      },
      async deleteFilter(attrHead, attrType) {
        const results = this.status.filter((obj, index) => {
          return index != attrType;
        });
        this.status = results;
      },
      async deleteLocationGoogleFilter(attrHead, attrType) {
        const results = this.locationGoogle.filter((obj, index) => {
          return index != attrType;
        });
        this.locationGoogle = results;
      },
      async deleteLocationFilter(attrHead, attrType) {
        const results = this.location.filter((obj, index) => {
          return index != attrType;
        });
        this.location = results;
      },
      onClearHandler() {
        this.assigned = null;
        this.dataCreated = null;
        this.requestDate = null;
        this.menu = false;
        this.menu1 = false;
        this.menu2 = false;
        this.menu3 = false;
        this.constructionStart = null;
        this.constructionEnd = null;
        this.nextFollowUpFormatted = null;
        this.nextFollowUp = null;
        this.Category = 0;
        this.subCategory = null;
        this.Task = null;
        this.budgetFrom = null;
        this.budgetTo = null;
        this.realEstateCodeRequested = null;
        this.sourceType = null;
        this.Comments = null;
        this.areasqmFrom = null;
        this.areasqmTo = null;
        this.plotAreasqmFrom = null;
        this.plotAreasqmTo = null;
        this.bedroomsFrom = null;
        this.bedroomsTo = null;
        this.facadeTo = null;
        this.facadeFrom = null;
        this.buildFrom = null;
        this.buildTo = null;
        this.bathroomsFrom = null;
        this.bathroomsTo = null;
        this.floorsFrom = null;
        this.floorsTo = null;
        this.noPlacesFrom = null;
        this.noPlacesTo = null;
        this.constructionFrom = null;
        this.constructionTo = null;
        this.status = [];
        this.propertyCharacteristics = [];
        this.description = null;
        this.agentAssignList = [];
        this.customerCreated = false;
        this.selectedMu = 0;
        this.customer = null;
        this.location = [];
        this.locationGoogle = [];
      },
      openDialog(type, data) {
        this.dialog = {
          open: true,
          type: type,
          data: data,
        };
      },
      closeDialog() {
        this.dialog = {
          open: false,
          type: null,
          data: null,
        };
      },
      async continueHandlerInit(type) {
        this.loading = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.loading = false;
          return false;
        }
        if (
          this.customer?.["_id"] &&
          [null, undefined, ""].includes(this.customer?.customer_evaluation)
        ) {
          let resp = await ContactRepo.getId(this.customer["_id"]);
          let fetchedContact = resp?.data?.contacts?.[0];
          if (
            [null, undefined, ""].includes(fetchedContact?.customer_evaluation)
          ) {
            this.loading = false;
            this.openDialog("createEvaluation", type);
          } else {
            this.continueHandler(type);
          }
        } else {
          this.loading = false;
          this.continueHandler(type);
        }
      },
      async onSubmitEval(customer_evaluation) {
        let type = this.dialog.data;
        this.closeDialog();
        this.customer.customer_evaluation = customer_evaluation;
        this.continueHandler(type);
      },
      continueHandler(type){
        if (type == 'create')
          this.createHandler()      
        else if (type =='edit')
          this.editHandler()
      },
      async createHandler() {
        this.loading = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.loading = false;
          return false;
        }
        let body = { unRead: true };
  
        if (this.cardData) {
          if (this.cardData.EstateCode != null)
            body["EstateCode"] = this.cardData.EstateCode;
          if (this.Comments != null) body["Comments"] = this.Comments;
          if (["Agent", "Direct"].includes(this.sourceType)) {
            if (this.source != null) body["source"] = this.source;
            body["sourceType"] = this.sourceType;
          }
          if (this.customer != null) body["ClientID"] = this.customer._id;
          if (this.requestStatus != null)
            body["RequestStatus"] = this.requestStatus;
          if (this.description != null) body["description"] = this.description;
          if (this.nextFollowUp != null)
            body["follow_up"] = CoreHelper.dateToEpoch(
              this.nextFollowUp
            );
        } else {
          if (this.Category != null) body["Category"] = this.Category;
          if (this.Task != null) body["Task"] = this.Task;
          if (this.assigned != null) body["Agent"] = this.assigned;
          if (this.description != null) body["description"] = this.description;
          if (this.requestDate != null) body["requestDate"] = this.requestDate;
          if (this.Comments != null) body["Comments"] = this.Comments;
          if (this.subCategory != null) body["Type"] = this.subCategory;
          if (this.requestStatus != null)
            body["RequestStatus"] = this.requestStatus;
          if (
            this.propertyCharacteristics &&
            this.propertyCharacteristics.length > 0
          )
            body["property_characteristics"] = this.propertyCharacteristics;
          if (this.status && this.status.length > 0)
            body["characteristics"] = this.status;
          if (this.content != null && this.content._id)
            body["_id"] = this.content._id;
          if (this.customer != null) body["ClientID"] = this.customer._id;
          if (["Agent", "Direct"].includes(this.sourceType)) {
            if (this.source != null) body["source"] = this.source;
            body["sourceType"] = this.sourceType;
          } else {
            body["source"] = null;
            body["sourceType"] = null;
          }
          if (this.nextFollowUp != null)
            body["follow_up"] = CoreHelper.dateToEpoch(
              this.nextFollowUp
            );
          if (this.categoryValue == 0) {
            body.Floors = [
              this.floorsFrom ? this.floorsFrom : -999999999,
              this.floorsTo ? this.floorsTo : 999999999,
            ];
            body.Bedrooms = [
              this.bedroomsFrom ? Number(this.bedroomsFrom) : -999999999,
              this.bedroomsTo ? Number(this.bedroomsTo) : 999999999,
            ];
            body.Bathrooms = [
              this.bathroomsFrom ? Number(this.bathroomsFrom) : -999999999,
              this.bathroomsTo ? Number(this.bathroomsTo) : 999999999,
            ];
            body.BuildYear = [ this.constructionStart, this.constructionEnd ];
            body.Unit = [
              this.areasqmFrom
                ? this.selectedMu == 1
                  ? Number(this.areasqmFrom * 1000)
                  : Number(this.areasqmFrom)
                : -999999999,
              this.areasqmTo
                ? this.selectedMu == 1
                  ? Number(this.areasqmTo * 1000)
                  : Number(this.areasqmTo)
                : 999999999,
            ];
            body.Land = [
              this.plotAreasqmFrom ? Number(this.plotAreasqmFrom) : -999999999,
              this.plotAreasqmTo ? Number(this.plotAreasqmTo) : 999999999,
            ];
          }
          if (this.categoryValue == 1) {
            body.Unit = [
              this.areasqmFrom
                ? this.selectedMu == 1
                  ? Number(this.areasqmFrom * 1000)
                  : Number(this.areasqmFrom)
                : -999999999,
              this.areasqmTo
                ? this.selectedMu == 1
                  ? Number(this.areasqmTo * 1000)
                  : Number(this.areasqmTo)
                : 999999999,
            ];
            body.Floors = [
              this.floorsFrom ? this.floorsFrom : -999999999,
              this.floorsTo ? this.floorsTo : 999999999,
            ];
            body.BuildYear = [ this.constructionStart, this.constructionEnd ];
          }
          if (this.categoryValue == 2) {
            body.Facade = [
              this.facadeFrom ? Number(this.facadeFrom) : -999999999,
              this.facadeTo ? Number(this.facadeTo) : 999999999,
            ];
            body.Build = [
              this.buildFrom ? Number(this.buildFrom) : -999999999,
              this.buildTo ? Number(this.buildTo) : 999999999,
            ];
            body.Unit = [
              this.areasqmFrom
                ? this.selectedMu == 1
                  ? Number(this.areasqmFrom * 1000)
                  : Number(this.areasqmFrom)
                : -999999999,
              this.areasqmTo
                ? this.selectedMu == 1
                  ? Number(this.areasqmTo * 1000)
                  : Number(this.areasqmTo)
                : 999999999,
            ];
          }
          if (this.categoryValue == 3) {
            body.Unit = [
              this.areasqmFrom
                ? this.selectedMu == 1
                  ? Number(this.areasqmFrom * 1000)
                  : Number(this.areasqmFrom)
                : -999999999,
              this.areasqmTo
                ? this.selectedMu == 1
                  ? Number(this.areasqmTo * 1000)
                  : Number(this.areasqmTo)
                : 999999999,
            ];
            body.Floors = [
              this.floorsFrom ? this.floorsFrom : -999999999,
              this.floorsTo ? this.floorsTo : 999999999,
            ];
            body.BuildYear = [ this.constructionStart, this.constructionEnd ];
          }
          body.Price = [
            this.budgetFrom ? Number(this.budgetFrom) : -999999999,
            this.budgetTo ? Number(this.budgetTo) : 999999999,
          ];
          body.location = this.location ? this.location : [];
          body.locationGoogle = this.locationGoogle ? this.locationGoogle : [];
        }
          try {
            this.dataCreated = await RequestDemandRepo.create(body);
            this.loading = false;
            this.openDialog("congratsPopUp");
          } catch (err) {
            console.error(err, "Error");
            this.loading = false;
          }
      },
      async editHandler() {
        this.loading = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.loading = false;
          return false;
        }
        var body = {};
        if (this.cardData) {
          body["EstateCode"] = this.cardData.EstateCode
            ? this.cardData.EstateCode
            : "";
          body["Comments"] = this.Comments ? this.comments : "";
          body["source"] = this.source ? this.source : "";
          body["ClientID"] = this.customer ? this.customer._id : "";
          body["RequestStatus"] = this.requestStatus ? this.requestStatus : "";
          body["_id"] = this.content._id ? this.content._id : "";
          body["description"] = this.description ? this.description : "";
          if (this.nextFollowUp != null)
            body["follow_up"] = CoreHelper.dateToEpoch(this.nextFollowUp);
        } else {
          body["Category"] = this.Category ? this.Category : "";
          body["Task"] = this.Task ? this.Task : "";
          body["Agent"] = this.assigned ? this.assigned : "";
          body["description"] = this.description ? this.description : "";
          body["Comments"] = this.Comments ? this.Comments : "";     
          if (this.nextFollowUp != null)
            body["follow_up"] = CoreHelper.dateToEpoch(this.nextFollowUp);
          body["RequestStatus"] = this.requestStatus ? this.requestStatus : "";
          body["ClientID"] = this.customer ? this.customer._id : "";
          body["property_characteristics"] = this.propertyCharacteristics
            ? this.propertyCharacteristics
            : [];
          if (this.subCategory != null) body["Type"] = this.subCategory;
          body["characteristics"] = this.status;
          body["_id"] = this.content._id ? this.content._id : "";
          if (["Agent", "Direct"].includes(this.sourceType)) {
            if (this.source != null) body["source"] = this.source;
            body["sourceType"] = this.sourceType;
          } else {
            body["source"] = null;
            body["sourceType"] = null;
          }
          if (this.categoryValue == 0) {
            body.Floors = [
              this.floorsFrom ? this.floorsFrom : -999999999,
              this.floorsTo ? this.floorsTo : 999999999,
            ];
            body.Bedrooms = [
              this.bedroomsFrom ? Number(this.bedroomsFrom) : -999999999,
              this.bedroomsTo ? Number(this.bedroomsTo) : 999999999,
            ];
            body.Bathrooms = [
              this.bathroomsFrom ? Number(this.bathroomsFrom) : -999999999,
              this.bathroomsTo ? Number(this.bathroomsTo) : 999999999,
            ];
            body.BuildYear = [ this.constructionStart , this.constructionEnd ];
            body.Unit = [
              this.areasqmFrom
                ? this.selectedMu == 1
                  ? Number(this.areasqmFrom * 1000)
                  : Number(this.areasqmFrom)
                : -999999999,
              this.areasqmTo
                ? this.selectedMu == 1
                  ? Number(this.areasqmTo * 1000)
                  : Number(this.areasqmTo)
                : 999999999,
            ];
            body.Land = [
              this.plotAreasqmFrom ? Number(this.plotAreasqmFrom) : -999999999,
              this.plotAreasqmTo ? Number(this.plotAreasqmTo) : 999999999,
            ];
          }
          if (this.categoryValue == 1) {
            body.Unit = [
              this.areasqmFrom
                ? this.selectedMu == 1
                  ? Number(this.areasqmFrom * 1000)
                  : Number(this.areasqmFrom)
                : -999999999,
              this.areasqmTo
                ? this.selectedMu == 1
                  ? Number(this.areasqmTo * 1000)
                  : Number(this.areasqmTo)
                : 999999999,
            ];
            body.Floors = [
              this.floorsFrom ? this.floorsFrom : -999999999,
              this.floorsTo ? this.floorsTo : 999999999,
            ];
            body.BuildYear = [ this.constructionStart, this.constructionEnd ];
          }
          if (this.categoryValue == 2) {
            body.Facade = [
              this.facadeFrom ? Number(this.facadeFrom) : -999999999,
              this.facadeTo ? Number(this.facadeTo) : 999999999,
            ];
            body.Build = [
              this.buildFrom ? Number(this.buildFrom) : -999999999,
              this.buildTo ? Number(this.buildTo) : 999999999,
            ];
            body.Unit = [
              this.areasqmFrom
                ? this.selectedMu == 1
                  ? Number(this.areasqmFrom * 1000)
                  : Number(this.areasqmFrom)
                : -999999999,
              this.areasqmTo
                ? this.selectedMu == 1
                  ? Number(this.areasqmTo * 1000)
                  : Number(this.areasqmTo)
                : 999999999,
            ];
          }
          if (this.categoryValue == 3) {
            body.Unit = [
              this.areasqmFrom
                ? this.selectedMu == 1
                  ? Number(this.areasqmFrom * 1000)
                  : Number(this.areasqmFrom)
                : -999999999,
              this.areasqmTo
                ? this.selectedMu == 1
                  ? Number(this.areasqmTo * 1000)
                  : Number(this.areasqmTo)
                : 999999999,
            ];
            body.Floors = [
              this.floorsFrom ? this.floorsFrom : -999999999,
              this.floorsTo ? this.floorsTo : 999999999,
            ];
            body.BuildYear = [this.constructionStart , this.constructionEnd ];
          }
          body.Price = [
            this.budgetFrom ? Number(this.budgetFrom) : -999999999,
            this.budgetTo ? Number(this.budgetTo) : 999999999,
          ];
          body.location = this.location ? this.location : [];
          body.locationGoogle = this.locationGoogle ? this.locationGoogle : [];
        }
        try {
          await RequestDemandRepo.update(body, this.content._id);
          this.onCloseEdit(this.content._id);
          this.loading = false;
        } catch (err) {
          console.error(err, "Error");
          this.loading = false;
        }
      },
      filterCustomers(item, queryText, itemText) {
        if (!isNaN(queryText)) {
          return item;
        }
        if (queryText.length >= 1) {
          let queryWord = queryText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toUpperCase()
            .split(" ");
          let itemWord = itemText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toUpperCase();
          return queryWord.every((item) => itemWord.includes(item));
        }
      },
      async fetchCustomerList(val, type) {
        try {
          let filters;
          this.customerAutocompleteLoader = true;
          if (![null, undefined, ""].includes(val) && type == "splitName") {
            if (val != null) {
              filters = { user_input: val /*CoreHelper.toRegex(val.split(" ")[0])*/ };
              // filters = { user_input: { $regex:  CoreHelper.toRegex(val.split(" ")[0]), $options: "i" } };
            }
          }
          let resp = await ContactRepo.getCustomers(filters, {
            page: 0,
            items: 20,
          });
          this.customerList = resp.data;
          this.customerAutocompleteLoader = false;
        } catch (e) {
          this.customerAutocompleteLoader = false;
          throw e;
        }
      },
      filterAgents(item, queryText, itemText) {
        if (queryText.length >= 1) {
          let queryWord = queryText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toUpperCase()
            .split(" ");
          let itemWord = itemText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toUpperCase();
          return queryWord.every((item) => itemWord.includes(item));
        }
      },
      async fetchSourceAgentList(val) {
        this.autocompleteLoaderAgent = true;
        let filters;
        let sourceAgent =
          this.sourceType == "Agent" && this.source ? this.source : "";
        if (this.userDetails.Role == "Admin")
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          ];
        else if (
          ["Secretary", "OfficeAdmin", "HR", "Associate"].includes(
            this.userDetails.Role
          )
        )
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ LastCompany: this.userDetails.Company }],
          ];
        else if (this.userDetails.Role == "Leader") {
          let ids = [];
          if (this.userDetails.Team && this.userDetails.Team.length > 0)
            ids = [...this.userDetails.Team, this.userDetails._id];
          else ids = [this.userDetails._id];
          filters = { _id: { $in: ids } };
          if (val != null) {
            filters.LastName = {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            };
          }
          let resp = await ContactRepo.get(filters, { page: 0, items: 60 });
          this.agentsSourceList = [
            ...resp.data.contacts.map((a) => {
              return { ...a, fullname: a.LastName + " " + a.FirstName };
            }),
            sourceAgent,
          ];
          return false;
        } else {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ _id: this.userDetails._id }],
          ];
          this.agentsSourceList = [sourceAgent];
          return false;
        }
        try {
          if (val != null) {
            if (filters.length == 1) {
              filters.push([]);
            }
            filters[1].push({
              ["LastName"]: {
                $regex: CoreHelper.toRegex(val.split(" ")[0]),
                $options: "i",
              },
            });
          }
          let resp = await ContactRepo.getAgents(filters, { page: 0, items: 60 });
          this.agentsSourceList = [
            ...resp.data.agents.map((a) => {
              return { ...a, fullname: a.LastName + " " + a.FirstName };
            }),
            sourceAgent,
          ];
          this.autocompleteLoaderAgent = false;
        } catch (e) {
          this.autocompleteLoaderAgent = false;
          throw e;
        }
      },
      async fetchAssignAgentsList(val) {
        this.autocompleteLoader = true;
        let filters;
        if (this.userDetails.Role == "Admin") {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
          ];
        } else if (
          ["Secretary", "OfficeAdmin", "HR", "Associate"].includes(
            this.userDetails.Role
          )
        ) {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ LastCompany: this.userDetails.Company }],
          ];
        } else if (this.userDetails.Role == "Leader") {
          let ids = [];
          if (this.userDetails.Team && this.userDetails.Team.length > 0)
            ids = [...this.userDetails.Team, this.userDetails._id];
          else ids = [this.userDetails._id];
          filters = {
            _id: { $in: ids },
          };
          if (val != null) {
            filters.LastName = {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            };
          }
          let resp = await ContactRepo.get(filters, {
            page: 0,
            items: 60,
          });
          this.agentAssignList = [
            ...resp.data.contacts.map((a) => {
              return { ...a, name: a.LastName + " " + a.FirstName };
            }),
            this.assigned,
          ];
          return false;
        } else {
          filters = [
            { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
            [{ _id: this.userDetails._id }],
          ];
          this.agentAssignList = [this.assigned];
          return false;
        }
        try {
          if (val != null) {
            if (filters.length == 1) {
              filters.push([]);
            }
            filters[1].push({
              ["LastName"]: {
                $regex: CoreHelper.toRegex(val.split(" ")[0]),
                $options: "i",
              },
            });
          }
          let resp = await ContactRepo.getAgents(filters, { page: 0, items: 60 });
          this.agentAssignList = [
            ...resp.data.agents.map((a) => {
              return { ...a, name: a.LastName + " " + a.FirstName };
            }),
            this.assigned,
          ];
          this.autocompleteLoader = false;
        } catch (e) {
          this.autocompleteLoader = false;
          throw e;
        }
      },
      onCloseAfterCreateHandler() {
        this.closeDialog();
        this.onCloseAfterCreate(this.dataCreated);
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .card{
    border:1px solid rgba(0,0,0,.12);
    padding:20px 50px;
  }
  .btn {
    text-transform: unset !important;
    color: #263573;
  }
  
  .v-btn-toggle--group > .v-btn.v-btn {
    border-color: #263573 !important;
  }
  
  .v-btn-toggle > .v-btn.v-btn {
    border-radius: 5px !important;
    border-width: thin !important;
    background-color: white !important;
  }
  
  .v-btn-toggle {
    border-radius: 4px;
    display: block;
    max-width: 100%;
  }
  
  .v-btn-toggle .v-btn.v-btn.v-size--default {
    min-width: 48px;
    min-height: 0;
  }
  
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: rgba(38, 53, 115, 1) !important;
    text-transform: capitalize !important;
    font-family: CenturyGothicBold !important;
    font-weight: 500 !important;
    background-color: white;
  }
  
  #active-button {
    background-color: #263573 !important;
  }
  
  .recBtn {
    height: 108px !important;
    width: 118px !important;
  }
  
  .recBtnWidth {
    width: 118px !important;
  }
  
  .btn-custom {
    text-transform: capitalize !important;
    font-family: CenturyGothicBold !important;
    font-weight: 500 !important;
    font-size: 15px;
    background-color: white;
  }
  .call-registration-body{
    max-height:600px;
    margin: unset;
  }
  .borderOutlined {
    outline-style: none;
    outline-color: #263573;
    outline-width: medium;
  
    border-color: #263573 !important;
    border-radius: 5px !important;
    border-width: thin !important;
  }
  
  .my-checkbox .v-label {
    font-size: 14px;
  }
  
  ::v-deep .my-checkbox .v-label {
    font-size: 14px;
  }
  .estate-chip {
    background: #deebe1 !important;
    font-size: 14px;
  }
  .filter-tag {
    min-width: 200px;
    width: 100%;
  }
  </style>