<template>
  <v-card :class="cardClass" min-width="280" max-width="416" min-height="435">
    <!-- max-width="300"
      max-height="435"
      min-width="300"
      min-height="435" 
      :class="cardClass"-->
    <v-row class="cardContent" style="cursor: pointer">
      <v-col class="pa-0">
        <v-row>
          <v-col class="pb-0 pt-7">
            <v-img
              :class="{ opacity: !isApproved, imgContent: true }"
              height="203"
              :src="tempImage"
              :lazy-src="tempImage"
              @error="imageError"
              max-width="379"
            >
              <v-row class="h50">
                <v-col class="d-flex align-end">
                  <v-row class="pl-6 d-flex align-center">
                    <span
                      v-if="booleanVip"
                      class="textDecorationBold vipClass mr-2"
                    >
                      VIP
                    </span>
                    <span
                      v-if="showDifPrice"
                      class="textDecorationBold discountClass pl-2 pr-2"
                    >
                      {{ getPrice.type + " " + getPrice.percentage + "%" }}
                    </span>
                  </v-row>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pa-0">
            <v-card-title
              class="textDecorationBold pb-2"
              style="color: #263573"
            >
              <v-tooltip
                bottom
                :disabled="
                  txtLength(
                    (title != undefined ? title : '') +
                      ' ' +
                      (unit != undefined ? '|' + unit + ' τ.μ.' : '')
                  ) < (mobileBreakpoint == true ? 60 : 28)
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="font16 pb-0 text-truncate"
                    align="left"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{
                      textOverFlow(
                        (![undefined, "", null].includes(title)
                          ? title
                          : "--") +
                          " " +
                          (![undefined, "", null].includes(unit)
                            ? "|" + unit + " τ.μ."
                            : "--"),
                        mobileBreakpoint == true ? 60 : 27
                      )
                    }}
                  </div>
                </template>
                <span
                  >{{ ![undefined, "", null].includes(title) ? title : "--" }}
                  {{
                    ![undefined, "", null].includes(unit)
                      ? "|" + unit + " τ.μ."
                      : "--"
                  }}</span
                >
              </v-tooltip>
            </v-card-title>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pa-0">
            <v-card-text class="textFontDecoration">
              <v-row>
                <v-col class="pt-0 pb-0">
                  <h3
                    class="font14 textContent textDecorationBold"
                    align="left"
                  >
                    {{ ![undefined, "", null].includes(price) ? price : "--" }}
                  </h3>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col class="pt-0 pb-0">
                  <v-tooltip bottom :disabled="txtLength(location) < 33">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="textContent font12 pb-0 text-truncate"
                        align="left"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          ![undefined, "", null].includes(location)
                            ? textOverFlow(
                                location,
                                mobileBreakpoint == true ? 60 : 32
                              )
                            : "--"
                        }}
                      </div>
                    </template>
                    <span>{{
                      ![undefined, "", null].includes(location)
                        ? location
                        : "--"
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col class="pt-2 pb-0">
                  <div
                    class="
                      textContent
                      textDecorationBold
                      font14
                      veryDarkGrayColor
                    "
                    align="right"
                  >
                    {{ code }}
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" class="mt-0 flex-nowrap">
                <v-col
                  class="
                    text-no-wrap
                    textContent
                    textDecorationBold
                    ml-3
                    flex-grow-0
                  "
                >
                  <span>
                    {{
                      ![undefined, "", null].includes(typeName)
                        ? typeName
                        : "--"
                    }}
                  </span>
                </v-col>
                <v-col class="mb-2 flex-grow-0" v-if="mobileBreakpoint != true">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="font16 pb-0"
                        align="left"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-img
                          height="23px"
                          width="23px"
                          :src="require('@/assets/realEstateCard/global.svg')"
                        >
                        </v-img>
                      </div>
                    </template>
                    <span> {{ timeAgo(RegisterationDay) }} </span>
                  </v-tooltip>
                </v-col>
                <v-col class="pl-0">
                  <div
                    class="text-no-wrap textContent textFontDecoration font14"
                    style="color: #263573"
                    align="right"
                  >
                    {{ typeExtraContent }}
                  </div>
                </v-col>
              </v-row>

              <div>
                <v-col class="pt-0">
                  <v-divider></v-divider>
                </v-col>
               
              </div>

              <v-row class="pt-4 justify-start align-end pb-9">
                <AvatarField
                  :data="getPhoto(fromDetails)"
                  :isImg="fromDetails.AgentPhoto ? true : false"
                  :showName="true"
                  :nameContent="
                    (fromDetails.AgentName ? fromDetails.AgentName + ' ' : '') +
                    (fromDetails.AgentSurName ? fromDetails.AgentSurName : '')
                  "
                  fontSizeName="14px"
                  :showSubContent="true"
                  :subContent="
                    fromDetails.LastCompany ? fromDetails.LastCompany : ''
                  "
                  backgroundColor="#77BD86"
                  :size="32"
                  fontSizeImg="14px"
                  nameContentClass="avatar-name-class"
                  subNameContentClass="avatar-subname-class"
                />
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="forApproveClass justify-center ma-0 textDecorationBold">
    </v-row>
  </v-card>
</template>
  
  <script>

  /* 
  Name:DemandEstateCard
  Description: Card to show real estate with basic info
  API Property   
    fromDetails: Object for the demand requester
    id: Id of card
    cardClass: String for the card
    image: String for image path
    title: String for card title
    price: String for price of object
    location: String for location of object
    code: String for unique code of card/object
    typeName: String for title of type
    typeExtraContent: String for sub title of type
    comments: Comments for the demand
    status: String for status of object
    rating: Array or Object for rating
    openRealEstateEditHandler: Function on edit card
    openRealEstateSliderHandler: Function on open card
    onDelete: Function on demand delete
    approvalActionHandler: Function on approve/reject
    isApproved: Boolean, when it is false we show aprove and reject btns
    unit: String, Number for price per square meter
    prices: Array of prices
    booleanVip: Boolean, vip can be true or false
    RegisterationDay: String, Number for date registered
    authObj: Object containing values necessary for authorization
     
    Components Used:
      AvatarField 
*/

import CoreHelper from "@/helpers/Core/core.js";
import AuthHelper from "@/helpers/Core/auth.js";
import AvatarField from "@/components/Fields/AvatarField/AvatarField.vue";
export default {
  name: "DemandEstateCard",
  components: {
    AvatarField,
  },
  props: {
    fromDetails: Object,
    id: [String, Number], // Id of card
    cardClass: { type: String, default: "DemandEstateCard" },
    image: String, // Image of card
    title: String, // Tile of card
    price: [String, Number], // price of object
    location: String, // location of object
    code: String, // unique code of card/object
    typeName: String, // Title of type
    typeExtraContent: String, //Sub title of type
    comments: [Number, String, Array], // Comments
    status: String, // status of object
    rating: [Array, Object], // rating
    openRealEstateEditHandler: Function, // Handler on edit card
    openRealEstateSliderHandler: Function, // Handler on open card
    onDelete: Function,
    approvalActionHandler: Function, //Handler on approve/reject
    isApproved: Boolean, //When it is false we show aprove and reject btns
    unit: [String, Number], //τιμη τμ
    prices: Array, // hint of price
    booleanVip: Boolean, //vip can be true or false
    RegisterationDay: [String, Number], //Date registered
    authObj: Object, // Object containing values necessary for authorization
  },

  created() {
    this.tempImage = this.getImage(this.image);
  },
  data() {
    return {
      loading: false,
      loader: false,
      loader2: false,
    };
  },
  computed: {
    showDifPrice() {
      if (this.prices) return this.prices.length > 1;

      return false;
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    getStatusClass() {
      if (
        ["Υπο διαπραγμάτευση", "Μη ενεργό", "Ενεργό", "Προκαταβολή"].includes(
          this.status
        )
      ) {
        return "whiteStatusBox";
      } else if (["Πουλήθηκε", "Ενοικιάστηκε"].includes(this.status)) {
        return "redStatusBox textDecorationBold";
      } else if (this.status == "Πρόχειρο") {
        return "grayStatusBox";
      } else if (this.status == "Μη δημοσιευμένη") {
        return "darkGrayStatusBox";
      } else {
        return "";
      }
    },
    getPrice() {
      let max = this.prices.length;
      if (max > 1) {
        let type =
          this.prices[max - 1].change_literal == "initial_price"
            ? "Αρχική τιμή"
            : this.prices[max - 1].change_literal == "price_increase"
            ? "ΑΥΞΗΣΗ ΤΙΜΗΣ"
            : "ΜΕΙΩΣΗ ΤΙΜΗΣ";
        return {
          type: type,
          percentage: this.prices[max - 1].change_percentage,
        };
      }
      return "";
    },
    avgReviewsRating() {
      let reviewsSum = 0;
      let revCnt = 0;
      if (this.rating && this.rating.length > 0) {
        this.rating.map((m) => {
          if (m.rating_average) {
            reviewsSum += m.rating_average;
            revCnt += 1;
          }
        });
      }
      let revAvg = 0;
      revAvg =
        revCnt != 0 ? parseFloat(reviewsSum / revCnt).toFixed(2) : revAvg;
      return {
        revAvg: revAvg,
        revCnt: revCnt,
      };
    },
    commentsCnt() {
      try {
        return this.comments.filter((d) => {
          if (!d.is_deleted) {
            return d;
          }
        }).length;
      } catch {
        return 0;
      }
    },
  },
  methods: {
    getPhoto(obj) {
      if (![undefined, "", null].includes(obj.AgentPhoto)) {
        return obj.AgentPhoto;
      } else {
        if (obj.AgentName && obj.AgentSurName) {
          return obj.AgentName.charAt(0) + obj.AgentSurName.charAt(0);
        } else if (obj.AgentName) {
          return obj.AgentName.charAt(1);
        } else if (obj.AgentSurName) {
          return obj.AgentSurName.charAt(1);
        }
        return "";
      }
    },
    txtLength(txt) {
      try {
        return txt.length;
      } catch {
        return 0;
      }
    },
    textOverFlow(text, length) {
      try {
        if (text.length <= length) return text;
        else return text.substr(0, length) + " ..";
      } catch {
        return text;
      }
    },
    approveAction(action) {
      this.loader = true;
      this.loader2 = true;
      this.approvalActionHandler(this.id, action);
    },
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    openRealEstateEdit() {
      this.openRealEstateEditHandler();
    },
    openRealEstate() {
      this.openRealEstateSliderHandler();
    },
    imageError() {
      this.tempImage = require("@/assets/realEstateCard/no-image.png");
    },
    getImage() {
      return CoreHelper.getImagePath({ path: this.image });
    },
    timeAgo(date) {
      try {
        return CoreHelper.time_ago_card(date);
      } catch {
        return "1";
      }
    },
    isAuthorized(action, data) {
      let key = AuthHelper.getKeyForAuth(this.userDetails.Role);
      return AuthHelper.authorization(
        action,
        data[key] ? data[key] : null,
        data["userID"] ? data["userID"] : null
      );
    },
  },
};
</script>
  
  <style scoped>
.vipClass {
  background: rgba(232, 144, 8, 0.75);
  color: #ffffff;
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeAgoSpan {
  font-family: "InterMedium";
  font-size: 14px;
  background-color: #263573b5;
  border-radius: 5px;
  padding: 2px 8px 3px 8px;
  color: white;
}

.discountClass {
  background: #e52628;
  color: #ffffff;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardContent {
  margin: 5% 5% 0% 5%;
}

.textContent {
  padding: 0px 0px 9px 0px !important;
}

.imgContent {
  margin: 0px !important;
  border-radius: 5px;
}

.imgBtn {
  height: 35px !important;
  width: 35px !important;
}

.font16 {
  font-size: 16px;
}

.font14 {
  font-size: 14px;
}

.font12 {
  font-size: 12px;
}

.opacity {
  filter: brightness(0.5);
}

.forApproveClass {
  font-size: 16px !important;
  color: #ffffff !important;
  position: absolute;
  width: 100%;
  top: 25%;
  cursor: pointer;
}
.h50 {
  height: 50%;
}
</style>
<style>
.DemandEstateCard .avatarSubContent{
  color: #263573;
}
.DemandEstateCard .avatar-name-class, .DemandEstateCard .avatar-subname-class {
  color: #263573;
}

</style>
  