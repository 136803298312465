<template>
    <v-col>
        <v-row>
            <v-col>
                <v-row align="center">
                   <v-col><CallerProperties :updateAttribute="updateAttribute" :attributes="data.demandData"/></v-col>
                   <v-col><CreateNewCall/></v-col>
                </v-row>
            </v-col>
           
        </v-row>
       
    </v-col>
</template>

<script>
/* 
  Name: CallCenter
  Description: CallCenter view is a page for phone calls view and edit
  
  Components Used:
    Filters
    CustomTable

  Endpoints Functions Called:
  -CompaniesRepo
    get
*/


import Repository from "@/services/Repository";
import CallerProperties from "@/components/Secretary/CallRegistration/CallerProperties.vue"
import CreateNewCall from "@/components/Secretary/CallRegistration/CreateNewCall.vue"

const CompaniesRepo = Repository.get("companies");
export default {
    name: "CallManager",
    components: {
        CreateNewCall,
        CallerProperties
},
    data() {
        return {
            activeTab: 0,
      tabs: [
        {
          name: "demand",
          path: "/createCall/demandCall",
        },
        {
          name: "recommendation",
          path: "/createCall/recommendationCall",
        },
        {
          name: "assignment",
          path: "/createCall/assignmentCall",
        },
      ],

      data: {
        demandData: {
          quickPartnerSearch: null,
          propertyCode: null,
          nextFollowUpFormatted: null,
          nextFollowUpStart: null,
          Task: null,
          Category: null,
          subCategory: null,
          priceFrom: null,
          priceTo: null,
          priceSqftFrom: null,
          priceSqftTo: null,
          floorMin: null,
          floorMax: null,
          constructionTo: null,
          constructionFrom: null,
          renovationTo: null,
          renovationFrom: null,
          status: [],
          checkbox: [],
          selectedMu: 0,
          importance: null,
          description: null,
          bedrooms: 0,
        },
      },
    };
  },
  computed: {
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
    async created() {
        await this.initList();
    },

    methods: {
        async initList() {
            this.companyList = await this.fetchCompany();
        },
        async fetchCompany() {
            try {
                const resp = await CompaniesRepo.get({
                    action: 'get_companies'
                });
                return resp.data;
            } catch (err) {
                console.log(err, "Error");
            }
        },
        // async fetchSecretary() {
        //     try {
        //         this.filterObject = await SecretaryHelper.setFilterObject(
        //             this.filterValues
        //         );
        //         this.closeDialog();
        //     } catch (e) {
        //         throw e;
        //     }
        // },
        openDialog(type, data) {
            this.dialog = {
                open: true,
                type: type,
                data: data,
            };
        },
        closeDialog() {
            this.dialog = {
                open: false,
                type: null,
                data: null,
            };

        },
        updateAttribute(kind, attribute, value) {
      this.data[kind][attribute] = value;
    },
        updateFieldValue(key, value) {
            this.filterValues[key] = value;
        },
        async clearAllFilter() {
            for (const key in this.filterValues) {
                this.updateFieldValue(key, null);
            }
            await this.fetchContacts();
        },
    },

}
</script>

<style scoped>
.btn-custom {
    text-transform: capitalize !important;
    font-family: CenturyGothicBold !important;
    font-weight: 500 !important;
    font-size: 15px;
    background-color: white;
}
</style>